<template>
<div>
    <div class="col-md-12 autoOrderItem mb-4">
        <div class="autoOrder-inputs autoOrder-inputs--orange">
            <div class="inputs-line inputs-line--orange mb-2 pb-1">
                <router-link to="/admin/preparation/assembly" class="btn-white-border me-2">К сборке</router-link>
                <router-link to="/admin/preparation/assembled" class="btn-white-border me-2">Собрано</router-link>
                <router-link to="/admin/preparation/history" class="btn-white-border me-2">История</router-link>
            </div>
        </div>
        <DayOfWeek />

    </div>

    <router-view></router-view>
</div>
</template>

<script>
//import OperatorPreparationItem from '@/components/OperatorPreparationItem.vue'
import DayOfWeek from '@/components/DayOfWeek.vue'
// import AdminMenuHorizont from '@/components/AdminMenuHorizont.vue'
export default {
    data() {
        return {
            opened: [],
        };
    },
    components: {
        //         Navbar,
        //  AdminMenuHorizont
        DayOfWeek,
        // OperatorPreparationItem
    },
    async mounted() {

    },
    methods: {
        toggle(id) {
            const index = this.opened.indexOf(id);
            if (index > -1) {
                this.opened.splice(index, 1)
            } else {
                this.opened.push(id)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.admin-header {
    font-size: 40px;
    line-height: 100.74%;
}

.cabinet {
    margin-top: 35px;
    padding-bottom: 80px;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;

    &__nav {
        width: 250px;
    }

    &__content {
        width: calc(100% - 326px);
        //background: #FFFFFF !important;

    }

}

.inputs-line {

    input[type="checkbox"],
    input[type="radio"] {
        opacity: 0;
        position: fixed;
        width: 0;
    }

    a {
        display: inline-block;
        background-color: #fff;
        padding: 16px 35px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 109.5%;
        /* or 15px */

        color: #FD7E14;
        text-decoration: none;
        border: 1px solid #FD7E14;
        ;
        margin-bottom: 10px;
        border-radius: 12px;
        margin-right: 10px;
        text-align: center;
        // width: 160px;
        cursor: pointer;
        background: #fff;
    }

    a.router-link-active {
        background-color: #FD7E14;
        border-color: #FD7E14;
        color: #fff;
    }

    // label:hover {
    //     background-color: $orange;
    //     border-color: $orange;
    //     color: #fff;
    // }

    // input[type="checkbox"]:focus+label {
    //     background-color: $orange;
    //     border-color: $orange;
    //     color: #fff;
    // }

    input[type="checkbox"]:checked+label,
    input[type="radio"]:checked+label {
        background-color: #3F2A2C;
        border-color: #3F2A2C;
        color: #fff;
    }

    &--orange {
        label {
            background: #F5F5F5;
            border: none;
            padding-left: 48px;
            padding-right: 48px;
            font-size: 18px;
        }

        input[type="checkbox"]:checked+label,
        input[type="radio"]:checked+label {
            background-color: $orange;
            border-color: $orange;
            color: #fff;
            border: none;
        }
    }
}
</style>
